<template>
  <div>
    <MasterHeader />
    <!-- TODO: check if already collaborating.. -->
    <div class="container">
      <form @submit.prevent="sendOffer()" v-if="user">
        {{ user }}
        <router-link
          :to="'/collaborations/' + this.$route.params.id"
          class="btn btn-link btn-sm"
          ><i class="fas fa-chevron-left"></i> Back to the
          collaboration</router-link
        >
        <div class="mt-4">
          <h3 class="text-capitalize mb-2">{{ user.company }}</h3>
          <div class="row">
            <div class="col-12 col-md-8">
              <div class="widget">
                <div class="widget-header">
                  <h4>Request Interview</h4>
                </div>
                <!-- comment-f1 : adding disabled class to the entire div and disabled attribute to the textarea if user not subscribed -->
                <div class="widget-body "
                    :title="!user.subscription ? 'Please subscribe to Excelerate if you want to be able to change the message to the students' : ''"
                    >
                  <div 
                    class="form-group mb-2 description offerDesc"
                    :class = "!user.subscription ? 'disabled' : ''"
                  >
                    <h5>Write a message to the students</h5>
                    <p>
                      Please write why you are interested in an interview with
                      the students.
                    </p>  
                    <textarea 
                      v-if = "user.company"
                      :disabled ="!user.subscription"
                      v-model="offerDetails.offerDescription"
                      rows="8"
                      class="form-control mx-auto background"
                      @keyup="validateCharactors('description')"
                      :class="{
                        'limit-exceeded': isDescriptionCharactersLimitExceeded,
                      }"
                      required
                    >
                    </textarea>
                    <div class="charactor-counter">
                      Characters {{ characters }}/2000
                    </div>
                  </div>                  
                  <div class="form-group">
                    <label>Department</label>
                    <select class="form-control" v-model="userRole">
                      <option v-for="(role, index) in designationList" :key="index" :value="role.id" :selected="(role == userRole)">{{role.designation}}</option>
                    </select>
                  </div>
                </div>
                <!-- /widget-body -->

                <div class="widget-footer">
                  <div class="form-group">
                    <!-- comment-f2 : change in text and render span conditionally if user does not have a subscription -->
                    <div>
                      <h5 class="mb-3">
                        What happens after you have sent the request?
                      </h5>
                      <p>
                        The ball is now on the students court. Either they will decline the request or they will accept it.
                        <br>
                        If they accept your invitation request you will get a confirmation mail. 
                        <br>
                        <span
                          v-if ="!user.subscription"
                        >
                        Following that mail, should you want to proceed 
                        then you must select a subscription.
                        </span>
                      </p>
                    </div>                    
                  </div>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        name="role"
                        id="offerTerms"
                        value="terms"
                        v-model="terms"
                        required
                      />
                      <label class="custom-control-label" for="offerTerms">
                        By checking this, you accept our
                        <router-link to="/company/terms" target="_blank"
                          ><u
                            >terms and conditions for collaborations</u
                          ></router-link
                        >
                      </label>
                    </div>
                  </div>                  
                  <div class="form-group">
                    <button
                      class="btn btn-primary btn-lg btn-block"
                      type="submit"
                      :class="{
                      disabled:
                        isDescriptionCharactersLimitExceeded || !terms                  
                    }"           
                      :disabled = "user.subscription === false"
                    >
                      Request interview
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4" >
              <h5 class="mb-4">Company details</h5>
              <div class="text-capitalize">{{ user.company }}</div>
              <div>Att. {{ user.firstName }} {{ user.lastName }}</div>
               (will now get the below data from the company table)
              <div>{{ companyDetails.billingStreetAddress }} company billing address</div>
              <div>
                {{ companyDetails.billingPostCode }} postal code
                {{ companyDetails.billingCity }} billing city
              </div>
              <div class="mb-4">{{ companyDetails.billingCountry }} billing country</div>
              <label>Your email</label>
              <div class="mb-2">{{ user.email }}</div>    
            </div>
          </div>
        </div>
      </form>
      <div v-else>
        <h2>You are not supposed to be here..</h2>
        <div class="alert alert-info" v-if="!user.company">
          Register your company before you can send an offer.
          <router-link
            to="/company/new"
            v-if="userRole === 'professional' && !user.company"
            >New company</router-link
          >
        </div>
        <div class="alert alert-success" v-if="isOwner">
          You've already sent an offer to the collaboration.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MasterHeader from "@/components/MasterHeader.vue";
import { db } from "../../firebase";
import { base64ToString } from "../../utils/utils";
// import { mapState } from 'vuex'

const role = base64ToString(localStorage.getItem('i_am'))
export default {
  data() {
    return {
      terms:false,
      collaboration: {},
      companyName : "",
      offerDetails: {
        offerDescription:
        "Hi students,\nWe would like to request an interview with you as we find your thesis topic interesting.\nPlease let us know if a collaboration with us would be of interest to you.\n\nAll the best\n",
        lookingFor: "",
        offerFeatures: [],
      },
      characters: 0,
      isDescriptionCharactersLimitExceeded: false,
      features: [
        "Work at company location",
        "Potential Data Material (under NDA)",
        "Interviews with employees (within reason)",
        "Access to dedicated contact person",
        "Lunch at company",
        "Free drinks",
      ],
      countries: ["Denmark", "Sweden", "Norway", "Germany", "Spain", "Finland"],
    };
  },
  components: {
    MasterHeader,
  },
    created() {
    const userType = base64ToString(localStorage.getItem('i_am'))
    const userVerified = localStorage.getItem('u-verified')
    if (userVerified == 0 && userType == 'student') {
      this.$router.push('/user/student-welcome')
    }
    if (userVerified == 0 && userType == 'professional') {
      this.$router.push('/user/welcome')
    }
    this.$store.dispatch('getCollaboration', this.$route.params.id)
  },
  computed: {
    user(){
      // const vm = this
      const userData = this.$store.getters.professionalData
      // if (userData.company){ 
      //   vm.offerDetails.offerDescription = `Hi students,\nWe would like to request an interview with you as we find your thesis topic interesting.\nPlease let us know if a collaboration with us would be of interest to you.\n\nAll the best\n ${userData.company}`
      // }
      return userData
    },
    userRole(){
      return role
    },
    isOwner: function () {
      var vm = this;
      var result = false;
      // var employees = this.$store.state.company.companyDetails.companyEmployees;
      // if (typeof employees !== typeof undefined) {
      //   result = employees.includes(vm.user.userId);
      // }
      return result;
    },
    collaborationMembers: function () {
      // var result = this.$store.state.collaboration.collaborationMembers;
      return [];
    },
    companyDetails: function () {
      // return this.$store.state.company.companyDetails;
      return []
    },
    userFullName: function () {
      // const fullName = this.user.firstName + " " + this.user.lastName;
      return 'test';
    },
    designationList: function(){
      return this.$store.getters.designationList
    }
  },
  methods: {
    validateCharactors(type) {
      if (type === "description") {
        this.characters = this.offerDetails.offerDescription.length;
        if (this.characters > 2000) {
          this.isDescriptionCharactersLimitExceeded = true;
        } else {
          this.isDescriptionCharactersLimitExceeded = false;
        }
      }
    },
    sendOffer() {
      var vm = this;      
      if(vm.characters <= 400){
        // todo early morning task to fix these issues
      var existingMembers =
      this.$store.getters.currentCollaboration;
      const data = {
        // collaborationTitle: this.collaboration.title,
        // collaborationSemester: this.collaboration.targetSemester,
        collaborationId: this.$route.params.id,
        offerDetails: this.offerDetails,
        // companyDetails: this.companyDetails,
        user: this.user,
      };
      this.$store.dispatch('newOffer', {...data, companyDetails: {
            imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSh_IevYZ6WbnfGOVzhjLBNYDfMq7_K_AQUWp9WU9xSgA&usqp=CAU&ec=48665701',
            companyName: 'some shit',
            industry: {
              name: 'some other shit',
            },
            companyDescription: 'wack asfdsf',
            companyURL: 'wjatrever',
            companyLinkedinUrl: 'asdsad',
            billingStreetAddress: 'sasas',
            billingPostCode: 'assa',
            billingCity: 'sadas',
            billingCountry: 'billingCountrys',
          }
        })
      //TODO: MAIL 'Student - Meeting request recieved'
      //EVERY MEMBER OF COLLABORATION
      //param1: firstName (receiver), param2: company.name, param3: collaboration.title, param4: collaboration.id

      // existingMembers.forEach(function (member, index) {
      //   const templateData = {
      //     toEmail: member.members.email,
      //     firstName: member.members.firstName,
      //     param1: vm.collaboration.title,
      //     param2: vm.companyDetails.companyName,
      //     param3: vm.collaboration.id,
      //     template: "Student - Meeting request recieved",
      //   };
      //   vm.$store.dispatch("sendMail", templateData);
      // });

      //TODO: MAIL 'Professional - Offer sent'
      //COMPANY USER
      //param1: firstName (receiver), param2: collaboration.title, param3: collaboration.id

      // const templateData = {
      //   toEmail: vm.user.email,
      //   firstName: vm.user.firstName,
      //   param1: vm.collaboration.title,
      //   param2: vm.collaboration.id,
      //   template: "Professional - Offer sent",
      // };

      // vm.$store.dispatch("sendMail", templateData);

      // this.$store.dispatch("newCompanyCollaborationOffer", data);
      }
    },
  },
};
</script>

<style scoped>
.background{
  background-color: #fbf6f6;
  color:#361d49;
}
.charactor-counter {
  float: right;
  width: 25%;
  text-align: right;
  padding-right: 5px;
  font-size: 12px;
  color: rgb(173, 173, 173);
}
.limit-exceeded {
  border: 1px solid red;
}
.widget{
  box-shadow: 0px 0px 6px #0000001A;;
}
.widget .widget-header{
  border-bottom: none;
}
.widget-header h4{
  font-weight: 600;
  font-size: 22px  
}
.widget .widget-body{  
  padding:30px; 
}
.description{
  padding:15px;
  border: 1px solid #e1e1e1;
}
.widget-footer{
  border-top: none;
}
.widget-body h5, .widget-footer h5 {
  font-weight: 600;
  font-size: 16px;
}
label{
  color: #000;
}
.disabled{
opacity:0.4;
}
</style>